import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Divider, makeStyles, Typography } from '@material-ui/core'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import EventNoteIcon from '@material-ui/icons/EventNote'
import Carousel from 'react-material-ui-carousel'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import Image from 'gatsby-plugin-sanity-image'
import BlockContent from '@sanity/block-content-to-react'
import blockContentserializer from '../components/Shared/BlockContentSerializer'
import useSEO from '../components/Shared/useSEO'
import { ReportQuery } from '../../graphql-types'

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(4),
    },
    heading: {
        marginTop: theme.spacing(2),
        textTransform: 'uppercase',
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    infoSection: {
        color: theme.palette.primary.main,
    },
    infoText: {
        marginRight: theme.spacing(2),
    },
    infoIcon: {
        marginRight: theme.spacing(0.5),
    },
    divider: {
        height: '0.5px',
        backgroundColor: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
}))

interface PostProps {
    data: ReportQuery
}

export default ({
    data: {
        sanityReport: { title, subtitle, publishedAt, author, mainImage, imageGallery, _rawBody },
    },
}: PostProps) => {
    useSEO({ title: title })
    const classes = useStyles()

    const [selectedImage, setSelectedImage] = useState(undefined)

    const handleImageClick = (idx: number) => {
        setSelectedImage(idx)
    }
    return (
        <>
            <Box className={classes.imageWrapper}>
                <Image
                    {...mainImage}
                    width={1280}
                    style={{
                        width: '100%',
                        maxWidth: 1440,
                        height: 'calc(100vw * (9 / 16))',
                        maxHeight: '60vh',
                        objectFit: 'cover',
                    }}
                    loading="eager"
                />
            </Box>

            <Container maxWidth="md" className={classes.container}>
                <Typography
                    color="secondary"
                    variant="h2"
                    component="h2"
                    className={classes.heading}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography gutterBottom variant="h4" component="h4" color="secondary">
                        {subtitle}
                    </Typography>
                )}
                <Typography variant="subtitle1" className={classes.infoSection}>
                    <Box display="flex" alignItems="center">
                        <EventNoteIcon className={classes.infoIcon} />
                        <span className={classes.infoText}>{publishedAt}</span>
                        <PermIdentityIcon className={classes.infoIcon} />
                        <span className={classes.infoText}>{author}</span>
                    </Box>
                </Typography>
                <Divider className={classes.divider} />
                <BlockContent blocks={_rawBody} serializers={blockContentserializer} />
                {!!imageGallery.length && (
                    <Carousel interval={7000} indicators={false}>
                        {imageGallery.map((img, idx) => (
                            <Image
                                key={img.asset._id}
                                {...img}
                                width={1280}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                loading="eager"
                            />
                        ))}
                    </Carousel>
                )}
            </Container>
        </>
    )
}

export const query = graphql`
    query Report($id: String!) {
        sanityReport(id: { eq: $id }) {
            title
            subtitle
            publishedAt(formatString: "DD. MMMM YYYY", locale: "de")
            author
            mainImage {
                ...ImageWithPreview
            }
            imageGallery {
                ...ImageWithPreview
            }
            _rawBody
        }
    }
`
